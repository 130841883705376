const unleashMixin = {
  computed: {
    isMarketplaceFinancialReviewFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.marketplace.financial.review"
      );
    },
    isEditVehicleMeasurementsFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.vehicles.edit-vehicle-measurements"
      );
    },
    isEditVehicleTypeFeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.vehicles.edit-vehicle-type");
    },
    isOrderAdditionalContactsFeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.orders.additionalContacts");
    },
    isMessagingSpaV3FeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.messaging.spav3");
    },
    isAccountCustomFieldsV2FeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.account.custom-fields-v2");
    },
    isMarketplaceHitchDashboardFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.marketplace.admin.hitch.dashboard"
      );
    },
    isHitchRestrictDriverInfoFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.hitch.config.restrict-driver-info"
      );
    },
    isHitchOrdersMultiVinSearchFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.hitch.orders.multi-vin-search"
      );
    },
    isTmsPlaceToMarketplaceOwnerSubCompaniesFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.tms.place-to-marketplace-owner-sub-companies"
      );
    },
    isMarketplaceShipperPaymentsAchFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.marketplace.shipper.payments.ach"
      );
    },
    isReportsThreeMonthsDateRangeFeatureEnabled() {
      return this.$unleashClient?.isEnabled(
        "spav2.reports.three-months-date-range"
      );
    },
    isRoleUamFeatureEnabled() {
      return this.$unleashClient?.isEnabled("spav2.role.uam");
    },
  },
};

export default unleashMixin;
