const ROLES = {
    ADMIN: "ROLE_RUNBUGGY_SUPER_ADMIN",
    DRIVER: "ROLE_TRANSPORTER_DRIVER", //
    AUCTION_HOUSE: "ROLE_AUCTION_ADMIN", //
    DEALER: "ROLE_SHIPPER_ADMIN", //
    SALES_PERSON: "ROLE_SALES_PERSON", //
    NOTIFICATIONS_ADMIN: "ROLE_NOTIFICATIONS_ADMIN", //
    INTERCOMPANY: "ROLE_INTERCOMPANY", //
    ENTERPRISE: "ROLE_ENTERPRISE_ADMIN",

    ///==================== NEW ROLES ===========================///

    RUNBUGGY_SUPER_ADMIN: "ROLE_RUNBUGGY_SUPER_ADMIN",
    RUNBUGGY_ADMIN: "ROLE_RUNBUGGY_ADMIN",
    RUNBUGGY_OPERATIONS: "ROLE_RUNBUGGY_OPERATIONS",
    RUNBUGGY_UAM: "ROLE_RUNBUGGY_UAM",

    TMS_ADMIN: "ROLE_TMS_ADMIN", //TMS Admin
    TMS_ADMIN_TRIAL: "ROLE_TMS_TRIAL_ADMIN", //TMS Admin Trial
    TMS_USER: "ROLE_TMS_USER", //TMS User
    TMS_PRICING_ADMIN: "ROLE_TMS_PRICING_ADMIN", //TMS Pricing Admin
    TMS_AUTOMATION_CONFIG: "ROLE_TMS_AUTOMATION_CONFIG", //TMS Automation Config
    TMS_SHIPPER: "ROLE_TMS_SHIPPER", //TMS Shipper Role
    TMS_TRANSPORTER: "ROLE_TMS_TRANSPORTER", //TMS Transportation Role
    TMS_TO_VIEW: "ROLE_TMS_TO_VIEW", //TMS Transportation Order View Only
    TMS_SO_VIEW: "ROLE_TMS_SO_VIEW", //TMS Shipper Order View Only
    TMS_HITCH: "ROLE_TMS_HITCH", //TMS Hitch Role
    TMS_UAM: "ROLE_TMS_UAM", //TMS UAM

    ENTERPRISE_ADMIN: "ROLE_ENTERPRISE_ADMIN",
    ENTERPRISE_ADMIN_TRIAL: "ROLE_ENTERPRISE_TRIAL_ADMIN",
    ENTERPRISE_USER: "ROLE_ENTERPRISE_USER",
    ENTERPRISE_USER_TRIAL: "ROLE_ENTERPRISE_TRIAL_USER",

    AUCTION_ADMIN: "ROLE_AUCTION_ADMIN",  //Auction House Admin
    AUCTION_ADMIN_TRIAL: "ROLE_AUCTION_TRIAL_ADMIN",  //Auction House Admin Trial
    AUCTION_USER: "ROLE_AUCTION_USER",  //Auction House User
    AUCTION_USER_TRIAL: "ROLE_AUCTION_TRIAL_USER",  //Auction House User Trial
    AUCTION_UAM: "ROLE_AUCTION_UAM",  //Auction House UAM
    AUCTION_UAM_TRIAL: "ROLE_AUCTION_TRIAL_UAM",  //Auction House UAM Trial

    SHIPPER_ADMIN: "ROLE_SHIPPER_ADMIN", //Shipper Admin
    SHIPPER_ADMIN_TRIAL: "ROLE_SHIPPER_TRIAL_ADMIN", //Shipper Admin Trial
    SHIPPER_USER: "ROLE_SHIPPER_USER", //Shipper User
    SHIPPER_USER_TRIAL: "ROLE_SHIPPER_TRIAL_USER", //Shipper User Trial
    SHIPPER_MANAGER: "ROLE_SHIPPER_MANAGER", //Shipper Manager
    SHIPPER_MANAGER_TRIAL: "ROLE_SHIPPER_TRIAL_MANAGER", //Shipper Manager Trial
    SHIPPER_BUSINESS_UNIT_MANAGER: "ROLE_SHIPPER_BUSINESS_UNIT_MANAGER", //Shipper Business Unit Manager
    SHIPPER_BUSINESS_UNIT_MANAGER_TRIAL: "ROLE_SHIPPER_TRIAL_BUSINESS_UNIT_MANAGER", //Shipper Business Unit Manager Trial
    SHIPPER_UAM: "ROLE_SHIPPER_UAM", //Shipper Admin
    SHIPPER_UAM_TRIAL: "ROLE_SHIPPER_TRIAL_UAM", //Shipper UAM Trial

    TRANSPORTER_DRIVER: "ROLE_TRANSPORTER_DRIVER", //Driver
    TRANSPORTER_DRIVER_TRIAL: "ROLE_TRANSPORTER_TRIAL_DRIVER", //Driver Trial
    TRANSPORTER_ADMIN: "ROLE_TRANSPORTER_ADMIN", //Transporter Admin
    TRANSPORTER_ADMIN_TRIAL: "ROLE_TRANSPORTER_TRIAL_ADMIN", //Transporter Admin Trial
    TRANSPORTER_USER: "ROLE_TRANSPORTER_USER", //Transporter User
    TRANSPORTER_USER_TRIAL: "ROLE_TRANSPORTER_TRIAL_USER", //Transporter User Trial
    TRANSPORTER_BROKER: "ROLE_TRANSPORTER_BROKER", //Transporter Broker
    TRANSPORTER_SUB_HAULER: "ROLE_TRANSPORTER_SUB_HAULER", //Transporter Broker
    TRANSPORTER_UAM: "ROLE_TRANSPORTER_UAM", //Transporter UAM Trial
    TRANSPORTER_UAM_TRIAL: "ROLE_TRANSPORTER_TRIAL_UAM", //Transporter UAM Trial

    INTEGRATION_ADMIN: "ROLE_TMS_INTEGRATION_ADMIN", //
    INTEGRATION_USER: "ROLE_TMS_INTEGRATION_USER",
    VIEW_CONFIG_ADMIN: "ROLE_VIEW_CONFIG_ADMIN", //
    FINANCE_ADMIN: "ROLE_FINANCE_ADMIN",
    FINANCE_REVIEW: "ROLE_FINANCE_REVIEW",
    ACCESS_RULE_ADMIN: "ROLE_ACCESS_RULE_ADMIN",
    TASK_MANAGEMENT: "ROLE_TASK_MANAGEMENT", //Task Management Feature Role
    SHIPPER: "ROLE_SHIPPER", //Shipper Feature Role
    TRANSPORTER: "ROLE_TRANSPORTER", //Transporter Feature Role
    TO_VIEW: "ROLE_TO_VIEW", //Transporter Feature Role - View Only
    SO_VIEW: "ROLE_SO_VIEW", //Shipper Feature Role - View Only

    TP_ACCOUNT_ADMIN: "ROLE_RUNBUGGY_TP_ACCOUNT_ADMIN", //Transporter Account Admin
    TP_ACCOUNT_USER: "ROLE_RUNBUGGY_TP_ACCOUNT_USER", //Transporter Account User
    SH_ACCOUNT_ADMIN: "ROLE_RUNBUGGY_SH_ACCOUNT_ADMIN", //Shipper Account Admin
    SH_ACCOUNT_USER: "ROLE_RUNBUGGY_SH_ACCOUNT_USER", //Shipper Account User
    CS_ACCOUNT_ADMIN: "ROLE_RUNBUGGY_CS_ACCOUNT_ADMIN", //Customer Account Admin
    CS_ACCOUNT_USER: "ROLE_RUNBUGGY_CS_ACCOUNT_USER", //Customer Account User

    TMS_TP_ACCOUNT_ADMIN: "ROLE_TMS_TP_ACCOUNT_ADMIN", //Transporter Account Admin
    TMS_TP_ACCOUNT_USER: "ROLE_TMS_TP_ACCOUNT_USER", //Transporter Account User
    TMS_SH_ACCOUNT_ADMIN: "ROLE_TMS_SH_ACCOUNT_ADMIN", //Shipper Account Admin
    TMS_SH_ACCOUNT_USER: "ROLE_TMS_SH_ACCOUNT_USER", //Shipper Account User
    TMS_CS_ACCOUNT_ADMIN: "ROLE_TMS_CS_ACCOUNT_ADMIN", //Customer Account Admin
    TMS_CS_ACCOUNT_USER: "ROLE_TMS_CS_ACCOUNT_USER", //Customer Account User

    PRIVATE_SHIPPER_ADMIN: "ROLE_PRIVATE_ADMIN", //Private Shipper Admin
    PRIVATE_SHIPPER_ADMIN_TRIAL: "ROLE_PRIVATE_TRIAL_ADMIN",  //Private Shipper Admin Trial
    PRIVATE_SHIPPER_USER: "ROLE_PRIVATE_USER",  //Private Shipper User
    PRIVATE_SHIPPER_USER_TRIAL: "ROLE_PRIVATE_TRIAL_USER",  //Private Shipper User Trial
    PRIVATE_SHIPPER_UAM: "ROLE_PRIVATE_UAM",  //Private Shipper UAM
    PRIVATE_SHIPPER_UAM_TRIAL: "ROLE_PRIVATE_TRIAL_UAM",  //Private Shipper UAM Trial

    PAYMENT_ADMIN: "ROLE_PAYMENT_ADMIN",
    PICKUP_CONTACT: "ROLE_PICKUP_CONTACT",
    PREVIEW_FEATURES: "ROLE_PREVIEW_FEATURES",
    PRICE_ADMIN: "ROLE_PRICE_ADMIN",
    GEO_FEATURES: "ROLE_GEO_FEATURES"
}

export default ROLES;
