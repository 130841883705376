/*jshint esversion: 6 */

import translations from "@/i18n/us_en/translations";

import moment from "moment";
import "moment-timezone";
import _get from "lodash/get";
import _find from "lodash/find";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _lowerCase from "lodash/lowerCase";

import TransportationOrderStatuses from "@/constants/transportationOrderStatuses";
import ShipperOrderStatuses from "@/constants/orderStatuses";
import VTOrderStatuses from "@/constants/vehicleTransforOrderStatuses";
import serviceTiers from "@/constants/serviceTiers";
import RunBuggyTasksApiService from "SC/services/runBuggyTasksApi";

import ROLES from 'SC/constants/roles';
import store from '@/store';

export const currencyFilterParams = {
  allowedCharPattern: "\\d\\-\\.\\$",
  numberParser: 'NUMBER_PARSERS.currencyFilter',
};

export function currencyValueFormatter(params) {
  const formatted = params.value ? parseFloat(params.value).toFixed(2) : "0.00";

  if (formatted.indexOf("-") === 0) {
    return "-$" + formatted.slice(1);
  }

  return "$" + formatted;
}

export function numberValueRoundFormatter(params) {
  return params.value ? parseFloat(params.value).toFixed() : "0";
}

export function dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
  const dateAsString = moment(cellValue).format("DD/MM/YYYY");
  const dateParts = dateAsString.split("/");
  const cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );

  if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
}

export function dateValueFormatter(params) {
  return _isEmpty(params.value)
    ? ""
    : formatDateTime(
      params.value,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
}

export function formatDateValue(value) {
  return _isEmpty(value)
    ? ""
    : formatDateTime(value, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function formatOnlyDateValue(value) {
  return _isEmpty(value)
    ? ""
    : formatDate(value, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function getDateValue(value) {
  if (value) {
    return moment(value).fromNow();
  }
  return null;
}

export function parseDateIgnoreTimezone(date, isMonthDayFormat) {
  if (isMonthDayFormat) {
    return moment.parseZone(date).format("MM/DD");
  }
  return moment.parseZone(date).format("MM/DD/YYYY");
}

export function parseETAWithTimezone(eta) {
  if (eta && eta !== null) {
    let fromDate = moment.utc(_get(eta, "start")).local();
    let toDate = moment.utc(_get(eta, "end")).local();
    let zone = moment.tz.guess();
    let timezone = moment.tz(zone).zoneAbbr();

    if (fromDate.date() === toDate.date()) {
      return `${toDate.format("MM/DD")} between ${fromDate.format(
        "hh:mm a"
      )} to ${toDate.format("hh:mm a")} ${timezone}`;
    } else {
      return `Between ${fromDate.format(
        "MM/DD hh:mm a"
      )} and ${toDate.format("MM/DD hh:mm a")} ${timezone}`;
    }
  }
}

const confirmCopied = 'Copied to Clipboard';

const copyToClipboard = async (event, text) => {
  try {
    await navigator.clipboard.writeText(text);
    event.target.textContent = confirmCopied;
    event.target.classList.add("teal");
    event.target.classList.add("accent-3");

    setTimeout(() => {
      event.target.classList.remove("teal");
      event.target.classList.remove("accent-3");
      event.target.textContent = text;
    }, 1000);
  } catch (err) {
    console.error("Failed to copy!", err);
  }
};
function isBusinessAddress(addressObj) {
  let personal =
    _includes(["private", "personal"], _lowerCase(_get(addressObj, "type"))) ||
    !_get(addressObj, "type");
  return !personal;
}

function getAvailableOrderAddressName(address) {
  let fallback = [
    _get(address, "addressComponents.city"),
    _get(address, "addressComponents.state"),
  ];
  return isBusinessAddress(address)
    ? _get(address, "name", fallback.join(", "))
    : fallback.join(", ");
}
function getAvailableOrderAddress(address) {
  let fallback = [
    _get(address, "addressComponents.city"),
    _get(address, "addressComponents.state"),
    _get(address, "addressComponents.postalCode"),
  ];
  return fallback.join(", ");
}

export function getAvailableOrderPickupName(params) {
  return getAvailableOrderAddressName(_get(params, "data.directions.0.pickup"));
}

export function getAvailableOrderDropoffName(params) {
  return getAvailableOrderAddressName(
    _get(params, "data.directions.0.dropOff")
  );
}

export function getAvailableOrderPickupAddress(params) {
  return getAvailableOrderAddress(_get(params, "data.directions.0.pickup"));
}

export function getAvailableOrderDropoffAddress(params) {
  return getAvailableOrderAddress(_get(params, "data.directions.0.dropOff"));
}

function obfuscateVin(vin) {
  let lastSix = vin.substr(vin.length - 6);
  return `******${lastSix}`;
}

export function vinsValueGetterObfuscate(params) {
  const vins = [];
  const vtoArray = _get(params, "data.vehicleTransferOrders");
  if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach((vto) => {
      if (vto.vehicle && vto.vehicle.vin) {
        vins.push(`${obfuscateVin(vto.vehicle.vin)}`);
      }
    });
  }
  return vins.length ? vins.join(", ") : "Not Available";
}

export function vinValueFormatter(params) {
  const notAvailable = 'Not Available';
  const hasTransporterRole = _get(store, 'getters.user/hasTransporterRole', false);
  const isTmsContextUser = _get(store, 'getters.user/isTmsContextUser', false);
  const vin = _get(params, 'data.vehicle.vin');
  const status = _get(params, 'data.status');

  if (vin) {

    if (!isTmsContextUser && (hasTransporterRole && status && (status === TransportationOrderStatuses.AVAILABLE ||
      status === TransportationOrderStatuses.AVAILABLE_PRIORITY))) {
      return `**********${vin.substr(10)}`;
    }
    return vin;
  }
  return notAvailable;
}

export function vinsValueGetter(params) {
  const vins = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders"));
  if (params.node.group) {
    return getMaskedOrRenderedVin(vtoArray);
  } else if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach(vto => {
      if (vto.vehicle && vto.vehicle.vin) {
        vins.push(getMaskedOrRenderedVin(vto));
      }
    });
  }
  return vins.length ? vins.join(', ') : 'Not Available';
}

export function vehicleTypesGetter(params) {
  const vehiclesType = [];
  const vtos = _get(
    params,
    "vehicleTransferOrders",
    _get(params, "data.vehicleTransferOrders"),
    []
  );

  if (vtos && vtos.length > 0) {
    vtos.forEach((vto) => {
      if (_get(vto, "vehicle.type")) {
        let vehicleTypeExists = vehiclesType.find(
          (vehicleType) => vehicleType === _get(vto, "vehicle.type")
        );
        if (!vehicleTypeExists) {
          vehiclesType.push(_get(vto, "vehicle.type"));
        }
      }
    });
  } else if (_get(vtos, "vehicle.type")) {
    vehiclesType.push(_get(vtos, "vehicle.type"));
  }
  return vehiclesType.length ? vehiclesType.join(", ") : "Not Available";
}

export function stockNumberValueGetter(params) {
  const stockNumbers = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders"));
  if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach(vto => {
      if (vto.vehicle && vto.vehicle.stockNumber) {
        stockNumbers.push(vto.vehicle.stockNumber);
      }
    });
  } else {
    const stockNumber = _get(params, "data.data.vehicleTransferOrders.vehicle.stockNumber")
    stockNumbers.push(stockNumber);
  }
  return stockNumbers.length ? stockNumbers.join(', ') : 'Not Available';
}

export function yearValueGetter(params) {
  const values = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders"));
  if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach(vto => {
      if (vto.vehicle && vto.vehicle.year) {
        values.push(vto.vehicle.year);
      }
    });
  } else {
    const value = _get(params, "data.data.vehicleTransferOrders.vehicle.year")
    values.push(value);
  }
  return values.length ? values.join(', ') : 'Not Available';
}
export function makeValueGetter(params) {
  const values = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders"));
  if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach(vto => {
      if (vto.vehicle && vto.vehicle.make) {
        values.push(vto.vehicle.make);
      }
    });
  } else {
    const value = _get(params, "data.data.vehicleTransferOrders.vehicle.make")
    values.push(value);
  }
  return values.length ? values.join(', ') : 'Not Available';
}
export function modelValueGetter(params) {
  const values = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders"));
  if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach(vto => {
      if (vto.vehicle && vto.vehicle.model) {
        values.push(vto.vehicle.model);
      }
    });
  } else {
    const value = _get(params, "data.data.vehicleTransferOrders.vehicle.model")
    values.push(value);
  }
  return values.length ? values.join(', ') : 'Not Available';
}

export function vtoRefsValueGetter(params) {
  const vtoRefs = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders"));
  if (params.node.group) {
    vtoRefs.push(_get(vtoArray, "referenceNumber"))
  } else if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach(vto => {
      if (vto.referenceNumber) {
        vtoRefs.push(vto.referenceNumber);
      }
    });
  }
  return vtoRefs.length ? vtoRefs.join(', ') : 'Not Available';
}

function getMaskedOrRenderedVin(vto) {
  const hasTransporterRole = _get(store, 'getters.user/hasTransporterRole', false);
  const isTmsContextUser = _get(store, 'getters.user/isTmsContextUser', false);
  const hasRunbuggySuperAdminRole = _get(store, 'getters.user/hasRunbuggySuperAdminRole', false);
  const hasRunbuggyAdminRole = _get(store, 'getters.user/hasRunbuggyAdminRole', false);

  if (!isTmsContextUser && !hasRunbuggySuperAdminRole && hasRunbuggyAdminRole &&
    (!hasTransporterRole && _get(vto, 'status') && (_get(vto, 'status') === TransportationOrderStatuses.AVAILABLE ||
      vto.status === TransportationOrderStatuses.AVAILABLE_PRIORITY))) {
    return `**********${_get(vto, 'vehicle.vin').substr(10)}`;
  } else {
    return vto ? `${_get(vto, 'vehicle.vin')}` : '';
  }
}

export function vinsCellRenderer(params) {
  const vins = [];
  const vtoArray = _get(params, "vehicleTransferOrders", _get(params, "data.vehicleTransferOrders", ''));
  if (params.node.group && _get(vtoArray, 'vehicle.vin')) {
    vins.push(
      `<span class="element-clipboard mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${_get(vtoArray, 'vehicle.vin')}</span>`
    );
  } else if (vtoArray && vtoArray.length > 0) {
    vtoArray.forEach((vto) => {
      if (vto.vehicle && vto.vehicle.vin) {
        vins.push(
          `<span class="element-clipboard mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${vto.vehicle.vin}</span>`
        );
      }
    });
  }

  document.querySelectorAll(".element-clipboard").forEach((vinSpan) => {
    vinSpan.addEventListener("click", async (event) => {
      event.preventDefault();
      if (!navigator.clipboard) {
        // Clipboard API not available
        return;
      }
      const text = event.target.innerText;
      text !== confirmCopied && (await copyToClipboard(event, text));
    });
  });

  return vins.length ? vins.join("") : "Not Available";
}

export function vtoVinValueGetter(params) {
  const hasTransporterRole = _get(store, 'getters.user/hasTransporterRole', false);
  const isTmsContextUser = _get(store, 'getters.user/isTmsContextUser', false);
  const vin = _get(params, "data.vehicle.vin");
  const status = _get(params, "data.status");

  if (!isTmsContextUser && (hasTransporterRole && status && (status === "AVAILABLE" || status === "AVAILABLE_PRIORITY"))) {
    return obfuscateVin(vin);
  } else return vin;
}

function getArrayOfFieldValues(params, path, fieldName) {
  const values = [];
  let objArray = _get(params.data, `${path}`, []);
  if (objArray && objArray.length > 0) {
    objArray.forEach(item => {
      if (item[fieldName]) {
        values.push(item[fieldName]);
      }
    });
  }
  return values;
}

export function sToStatusValueGetter(params) {
  const values = getArrayOfFieldValues(params, "transportationOrders", "status");
  return values.join(", ");
}

export function sToStatusCellRenderer(params) {
  let renderers = [];
  const values = getArrayOfFieldValues(params, "transportationOrders", "status");
  values.forEach(value => {
      let key = `vehicleTransferOrder.status.${value}`;
      const renderer = `<span class="element-clipboard mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${_get(translations, key)}</span>`
      renderers.push(renderer);
  });
  return renderers.join("");
}

export function sToOnHoldReasonValueGetter(params) {
  const statuses = getArrayOfFieldValues(params, "transportationOrders", "putOnHoldReason");
  return statuses.join(", ");
}

export function sToOnHoldReasonCellRenderer(params) {
  let renderers = [];
  const values = getArrayOfFieldValues(params, "transportationOrders", "putOnHoldReason");
  values.forEach(value => {
      const renderer = `<span class="element-clipboard mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${value}</span>`
      renderers.push(renderer);
  });
  return renderers.join("");
}

export function formatDateTime(value, timeZone = "America/Los_Angeles") {
  return moment(value).tz(timeZone).format("MM/DD/YYYY hh:mm A");
}

export function formatDate(value, timeZone = "America/Los_Angeles") {
  return moment(value).tz(timeZone).format("MM/DD/YYYY");
}

export function booleanRenderer(params) {
  let value = undefined;
  if (params.value) {
    value = params.value;
  } else {
    const colId = _get(params, 'colDef.field', _get(params, "column.colDef.field", _get(params, 'colDef.colId')));
    value = _get(params.data, colId);
  }
  let type = typeof value;
  let booleanValue = false;
  if (value) {
    if (type === "string") {
      booleanValue = (value === "true" || value.toLowerCase() === "yes");
    } else {
      booleanValue = value;
    }
  }
  return `<span class="mdi ${booleanValue ? "mdi-check green--text" : "mdi-close red--text"
    }"></span> ${booleanValue ? "Yes" : "No"}`;
}

export function booleanGetter(params) {
  let value = undefined;
  if (params.value) {
    value = params.value;
  } else {
    const colId = _get(params, 'colDef.field', _get(params, "column.colDef.field", _get(params, 'colDef.colId')));
    value = _get(params.data, colId);
  }
  let type = typeof value;
  let booleanValue = false;
  if (value) {
    if (type === "string") {
      booleanValue = value === "true";
    } else {
      booleanValue = value;
    }
  }
  return (booleanValue) ? "Yes" : "No";
}

export function badgeColumnRenderer(params) {
  let isActiveBadge = true;
  if (!_get(params, "value") || _get(params, "value") === "") {
    isActiveBadge =  false;
  }
  return `<span class="mdi ${isActiveBadge ? "mdi-check green--text" : "mdi-close red--text"
    }"></span> ${isActiveBadge ? "Yes" : "No"}`;
}

export function badgeColumnFormatter(params) {
  if (!_get(params, "value") || _get(params, "value") === "") {
    return "No"
  }
  return "Yes";
}

export function valueFormatterValue(params) {
  return params.value;
}

export function booleanFormatter(params) {
  return params.value === "true" ? "Yes" : "No";
}
export function booleanReverseFormatter(params) {
  return params.value === "true" ? "No" : "Yes";
}

export function getStatusTransportationOrder(params) {
  let orderStatus = getActualTransportationOrderStatus(_get(params.data, "status", "AVAILABLE"), _get(params.data, "timeline"),)
  const key = `transportationOrder.status.${orderStatus}`;
  return _get(translations, key);
}

export function getStatusTransportationOrderTask(params) {
  const key = `transportationOrder.status.${_get(
    params.data,
    "data.transportationOrders.0.status"
  )}`;
  return _get(translations, key);
}

export function transportationOrderStatusFormatter(params) {
  let orderStatus = getActualTransportationOrderStatus(_get(params.data, "status", "AVAILABLE"), _get(params.data, "timeline"),)
  const key = `transportationOrder.status.${orderStatus}`;
  return _get(translations, key);
}
function getActualTransportationOrderStatus(currentStatus, timeline) {
  if (currentStatus === "DRIVER_ASSIGNED" && timeline) {
    let size = timeline.length;
    currentStatus = _get(timeline[size - 1], 'name', currentStatus);
    if (_get(timeline[size - 1], 'name') === "DRIVER_ASSIGNED" && _get(timeline[size - 2], 'name') === "DRIVER_REJECTED") {
      timeline = timeline.slice(0, -2);
      size = timeline.length;
      currentStatus = _get(timeline[size - 1], 'name', currentStatus);
      getActualTransportationOrderStatus(currentStatus, timeline);
    }
  }
  return currentStatus;
}


export function shipperTransportationOrderStatusFormatter(params) {
  let transportationOrders = _get(params.data, "transportationOrders", []);
  let statusses = [];
  transportationOrders.forEach((order) => {
    let orderStatus = getActualTransportationOrderStatus(_get(order, "status", "AVAILABLE"), _get(order, "timeline"),)
    const key = `transportationOrder.status.${orderStatus}`;
    statusses.push(_get(translations, key));
  });
  return statusses.join(", ");
}

export function vehiclesCellRenderer(params) {
  const value = parseInt(params.value) || 0;
  return (value > 1 ? '<div class="d-flex justify-center"><b>' + value + '</b><i class="mdi mdi-car-multiple mdi-18px ml-2"></i></div>' :
    '<div class="d-flex justify-center"><b>' + value + '</b><i class="mdi mdi-car mdi-18px ml-2"></i></div>');
}

export function getPayoutValue(params) {
  return _get(params.data, "pricing.payout", 0);
}

const transportationOrderStatusMap = {
  [TransportationOrderStatuses.AVAILABLE]: "Available",
  [TransportationOrderStatuses.AVAILABLE_PRIORITY]: "Available Priority",
  [TransportationOrderStatuses.ON_HOLD]: "On Hold",
  [TransportationOrderStatuses.COMPLETED]: "Completed",
  [TransportationOrderStatuses.VEHICLES_SIGNATURE_DELIVERED]:
    "Vehicle Signature Delivered",
  [TransportationOrderStatuses.VEHICLES_DELIVERED]: "Vehicles Delivered",
  [TransportationOrderStatuses.PAYOUT_PROCESSING]: "Payout Processing",
  [TransportationOrderStatuses.PAYOUT_COMPLETE]: "Payout Complete",
  [TransportationOrderStatuses.VEHICLES_CLAIMED]: "Claimed",
  [TransportationOrderStatuses.DRIVER_REJECTED]: "Driver Rejected",
  [TransportationOrderStatuses.CANCELED]: "Canceled",
  [TransportationOrderStatuses.DETACHED]: "Detached",
  [TransportationOrderStatuses.VEHICLES_SIGNATURE_PICKEDUP]:
    "Vehicle Signature Picked Up",
  [TransportationOrderStatuses.VEHICLES_UNCLAIMED]: "Under Review",
  [TransportationOrderStatuses.VEHICLES_PICKEDUP]: "Vehicles Picked Up",
  [TransportationOrderStatuses.DRIVER_ASSIGNED]: "Assigned",
  [TransportationOrderStatuses.DRIVER_ACCEPTED]: "Accepted",
  [TransportationOrderStatuses.DRIVER_ARRIVED]: "Arrived",
  [TransportationOrderStatuses.PROVIDED_ETA_DROPOFF]: "ETA Dropoff",
  [TransportationOrderStatuses.CREATED]: "Created",
  [TransportationOrderStatuses.DRAFT]: "Draft",
  [TransportationOrderStatuses.DRAFT_CREATED]: 'Draft Created',
  [TransportationOrderStatuses.READY]: 'Ready',
  [TransportationOrderStatuses.PICKED_UP]: 'Picked Up',
  [TransportationOrderStatuses.ASSIGNED]: 'Assigned',
  [TransportationOrderStatuses.COMPLETE]: 'Complete',
  [TransportationOrderStatuses.CLAIMED]: 'Claimed',
  [TransportationOrderStatuses.ACCEPTED]: 'Accepted',
  [TransportationOrderStatuses.MARKETPLACE_PLACED]: 'Marketplace Placed',
  [TransportationOrderStatuses.MARKETPLACE_AVAILABLE]: 'Marketplace Available',
  [TransportationOrderStatuses.MARKETPLACE_CLAIMED]: 'Marketplace Claimed',
  [TransportationOrderStatuses.MARKETPLACE_IN_TRANSIT]: 'Marketplace In Transit',
  [TransportationOrderStatuses.MARKETPLACE_ON_HOLD]: 'Marketplace On Hold'
};

export const statusTransportationOrderFilterParams = {
  values: Object.keys(transportationOrderStatusMap),
  valueFormatter: 'VALUE_FORMATTERS.statusTransportationOrder'
};

export const shipperStatusTaskValueFormatter = (params) => {
  if (params.value) {
    let values = params.value.split(",");
    if (values.length > 1) {
      return values
        .map((_value) => {
          return transportationOrderStatusMap[_value];
        })
        .join(", ");
    } else {
      return transportationOrderStatusMap[values[0]];
    }
  } else {
    let value = _get(params, 'data.transportationOrders.0.status', _get(params, 'data.status'));
    return transportationOrderStatusMap[value]
  }
};

export const rowIndexDef = {
  field: "rowIndex",
  colId: "rowIndex",
  enableRowGroup: false,
  resizable: false,
  sortable: false,
  headerName: "#",
  lockPinned: true,
  maxWidth: 55,
  valueGetter: 'VALUE_GETTERS.rowIndex',
};

function formatWindow(window) {
  if (_get(window, "start") === _get(window, "end")) {
    let date = _get(window, "start", _get(window, "end", ""));
    return parseDateIgnoreTimezone(date);
  } else {
    return parseETAWithTimezone(window);
  }
}

function formatWindowStartDate(window) {
  let date = _get(window, "start", "");
  return parseDateIgnoreTimezone(date);
}

/*
-- START
REFACTOR PICKUP/DROPOFF DATES TO DISPLAY AVAILABLE AND BLACKOUT DATES
GRID WILL NOW USE CUSTOM FIELDS (availableWindows & blackoutWindows) FROM DIRECTIONS
*/
function getWindowDates(params) {
  let field = _get(params, 'colDef.field');
  if (field.includes(".start")) {
    field = field.substring(0, field.indexOf(".start"));
  }
  let windows =  _get(params.data, field, []);
  return (windows instanceof Array) ? windows : Array.of(windows);
}

export function windowDatesValueGetter(params) {
  let windows = [];
  let windowDates = getWindowDates(params);
  windowDates.forEach(window => {
    if(_get(window, "available", true))
      windows.push(formatWindowStartDate(window));
  });
  return windows.length ? windows.join(", ") : "Not Set";
}
export function windowDatesCellRenderer(params) {
  let windows = [];
  let windowDates = getWindowDates(params);
  windowDates.forEach(window => {
    windows.push(
      `<span class="mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--small" style="white-space: nowrap;">${formatWindowStartDate(window)}</span>`
    );
  })
  return windows.length ? windows.join(", ") : "Not Set";
}
/*
-- END
*/

export function pickupWindowsDatesValueGetter(params) {
  let windows = [];
  let pickupWindows = _get(
    params.data,
    "directions.0.pickup.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.pickup.windows",
      _get(
        params.data,
        "vehicleTransferOrders.0.directions.pickup.windows",
        []
      )
    )
  );
  pickupWindows.forEach(window => {
    windows.push(formatWindowStartDate(window));
  })
  return windows.length ? windows.join(", ") : "Not Set";
}
export function pickupWindowsDatesCellRenderer(params) {
  let windows = [];
  let pickupWindows = _get(
    params.data,
    "directions.0.pickup.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.pickup.windows",
      _get(
        params.data,
        "vehicleTransferOrders.0.directions.pickup.windows",
        []
      )
    )
  );
  pickupWindows.forEach(window => {
    windows.push(
      `<span class="mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--small" style="white-space: nowrap;">${formatWindowStartDate(window)}</span>`
    );
  })
  return windows.length ? windows.join(", ") : "Not Set";
}

export function dropOffWindowsDatesValueGetter(params) {
  let windows = [];
  let dropOffWindows = _get(
    params.data,
    "directions.0.dropOff.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.dropoff.windows",
      _get(
        params.data,
        "vehicleTransferOrders.0.directions.dropoff.windows",
        []
      )
    )
  );
  dropOffWindows.forEach(window => {
    windows.push(formatWindowStartDate(window));
  })
  return windows.length ? windows.join(", ") : "Not Set"
}
export function dropOffWindowsDatesCellRenderer(params) {
  let windows = [];
  let dropOffWindows = _get(
    params.data,
    "directions.0.dropOff.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.dropoff.windows",
      _get(
        params.data,
        "vehicleTransferOrders.0.directions.dropoff.windows",
        []
      )
    )
  );
  dropOffWindows.forEach(window => {
    windows.push(
      `<span class="mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--small" style="white-space: nowrap;">${formatWindowStartDate(window)}</span>`
    );
  })
  return windows.length ? windows.join(", ") : "Not Set";
}

export function pickupWindowsValueGetter(params) {
  let windows = [];
  let pickupWindows = _get(
    params.data,
    "directions.0.pickup.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.pickup.windows",
      []
    )
  );
  pickupWindows.forEach(window => {
    windows.push(formatWindow(window));
  })
  return windows.length ? windows.join(", ") : "Not Set";
}
export function pickupWindowsCellRenderer(params) {
  let windows = [];
  let pickupWindows = _get(
    params.data,
    "directions.0.pickup.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.pickup.windows",
      []
    )
  );
  pickupWindows.forEach(window => {
    windows.push(
      `<span class="mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${formatWindow(window)}</span>`
    );
  })
  return windows.length ? windows.join("") : "Not Set";
}

export function dropOffWindowsValueGetter(params) {
  let windows = [];
  let dropOffWindows = _get(
    params.data,
    "directions.0.dropOff.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.dropoff.windows",
      []
    )
  );
  dropOffWindows.forEach(window => {
    windows.push(formatWindow(window));
  })
  return windows.length ? windows.join(", ") : "Not Set";
};
export function dropOffWindowsCellRenderer(params) {
  let windows = [];
  let dropOffWindows = _get(
    params.data,
    "directions.0.dropOff.windows",
    _get(
      params.data,
      "data.vehicleTransferOrders.directions.dropoff.windows",
      []
    )
  );
  dropOffWindows.forEach(window => {
    windows.push(
      `<span class="mr-2 v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${formatWindow(window)}</span>`
    );
  })
  return windows.length ? windows.join("") : "Not Set";

}

export function serviceTiersRenderer(params) {
  let serviceTiers = [];
  let serviceTiersVal = [];
  if (!params.node.group) {
    let vehicleTransferOrders = _get(params.data, "vehicleTransferOrders", []);
    vehicleTransferOrders.forEach((vto) => {
      let sTier = _get(vto, "fare.name");
      if (sTier && !serviceTiersVal.includes(sTier)) {
        serviceTiersVal.push(sTier);
        serviceTiers.push(
          `<span class="mr-2 text-uppercase v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${sTier}</span>`
        );
      }
    });
  } else {
    let vto = _get(params.data, "vehicleTransferOrders", {});
    let sTier = _get(vto, "fare.name");
    if (sTier && !serviceTiersVal.includes(sTier)) {
      serviceTiersVal.push(sTier);
      serviceTiers.push(
        `<span class="mr-2 text-uppercase v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${sTier}</span>`
      );
    }
  }
  return serviceTiers.length ? serviceTiers.join("") : "Not Available";

}

export function serviceTiersGetter(params) {
  let serviceTiersVal = [];
  if (!params.node.group) {
    let vehicleTransferOrders = _get(params.data, "vehicleTransferOrders", []);
    vehicleTransferOrders.forEach((vto) => {
      let sTier = _get(vto, "fare.name");
      if (sTier && !serviceTiersVal.includes(sTier)) {
        serviceTiersVal.push(sTier);
      }
    });
  } else {
    let vto = _get(params.data, "vehicleTransferOrders", {});
    let sTier = _get(vto, "fare.name");
    if (sTier && !serviceTiersVal.includes(sTier)) {
      serviceTiersVal.push(sTier);
    }
  }
  return serviceTiersVal.length ? serviceTiersVal.join(", ") : "Not Available";
}

export function serviceTiersTaskRenderer(params) {
  let vehicleTransferOrders = _get(
    params.data,
    "data.vehicleTransferOrders",
    []
  );
  let serviceTiers = [];
  let serviceTiersVal = [];
  vehicleTransferOrders.forEach((vto) => {
    let sTier = _get(vto, "fare.name");
    if (sTier && !serviceTiersVal.includes(sTier)) {
      serviceTiersVal.push(sTier);
      serviceTiers.push(
        `<span class="mr-2 text-uppercase v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${sTier}</span>`
      );
    }
  });
  return serviceTiers.length ? serviceTiers.join("") : "Not Available";
}

export function serviceTierRenderer(params) {
  if (params.value)
    return `<span class="mr-2 text-uppercase v-chip v-chip--label v-chip--no-color theme--light v-size--x-small" style="white-space: nowrap;">${params.value}</span>`;
  else return "Not Available";
}

export const serviceTiersFilterParams = {
  values: serviceTiers,
  valueFormatter: 'VALUE_FORMATTERS.valueFormatterValue',
};

const shipperStatusMap = {
  [ShipperOrderStatuses.COMPLETED]: "Completed",
  [ShipperOrderStatuses.ERROR]: "Need Attention",
  [ShipperOrderStatuses.CREATED]: "Placed",
  [ShipperOrderStatuses.CANCELED]: "Canceled",
  [ShipperOrderStatuses.DRAFT_CREATED]: "Draft",
  // [ShipperOrderStatuses.CHANGE_REQUEST]: 'Change Request'
};

export const shipperStatusFilterParams = {
  values: Object.keys(shipperStatusMap),
  valueFormatter: 'VALUE_FORMATTERS.shipperStatusFormatter',
};

export const shipperStatusValueFormatter = (params) => {
  return shipperStatusMap[params.value];
};

const suspendedEnum = {
  YES: true,
  NO: false,
};

const suspendedMap = {
  [suspendedEnum.YES]: "Suspended",
  [suspendedEnum.NO]: "Not Suspended",
};

export const taskSuspendedParams = {
  values: Object.keys(suspendedMap),
  valueFormatter(params) {
    return _get(suspendedMap, params.value, params.value);
  },
};

export const taskSuspendedValueFormatter = (params) => {
  return suspendedMap[params.value];
};

const vinStatusMap = {
  [VTOrderStatuses.DRAFT]: "Draft",
  [VTOrderStatuses.READY]: "Ready",
  [VTOrderStatuses.ON_HOLD]: "On Hold",
  [VTOrderStatuses.AVAILABLE]: "Available",
  [VTOrderStatuses.CLAIMED]: "Claimed",
  [VTOrderStatuses.UNCLAIMED]: "Under Review",
  [VTOrderStatuses.ASSIGNED]: "Assigned",
  [VTOrderStatuses.REJECTED]: "Rejected",
  [VTOrderStatuses.ACCEPTED]: "Accepted",
  [VTOrderStatuses.DRIVER_ARRIVED]: "Driver Arrived",
  [VTOrderStatuses.PICKED_UP]: "Picked up",
  [VTOrderStatuses.SIGNATURE_ON_PICKUP]: "Signature on Pickup",
  [VTOrderStatuses.DELIVERED]: "Delivered",
  [VTOrderStatuses.CANCELED]: "Canceled",
  [VTOrderStatuses.COMPLETE]: "Complete",
  [VTOrderStatuses.ERROR]: "Error",
  [VTOrderStatuses.UNLOADED]: "Unloaded",
  [VTOrderStatuses.PROVIDED_ETA_DROPOFF]: "ETA Dropoff"
};

export const vinStatusFilterParams = {
  values: Object.keys(vinStatusMap),
  valueFormatter(params) {
    return _get(vinStatusMap, params.value, params.value);
  },
};

export const vinStatusValueFormatter = (params) => {
  return vinStatusMap[params.value];
};

export const onlyEqualAndNotEqualFilterParams = {
  filterOptions: ["equals", "notEqual"],
};

// TASK MANAGEMENT
export function tagsRenderer(params) {
  if (params.value && params.value.length > 0) {
    let tagsStr = "";
    params.value.forEach((tag) => {
      tagsStr += `<span class="mr-2 text-uppercase v-chip v-chip--label v-chip--no-color theme--light v-size--x-small">${tag}</span>`;
    });
    return tagsStr;
  } else {
    return "- -";
  }
}

export function suspendedRenderer(params) {
  if (params.value) {
    return `<i class="mdi mdi-check-bold ml-10 green--text mdi-18px"></i>`;
  }
}

export function rolesRenderer(params) {
  if (params.value && params.value.length > 0) {
    let rolesStr = "";
    params.value.forEach((role) => {
      rolesStr += `<span class="mr-2 text-uppercase v-chip v-chip--label v-chip--no-color theme--light v-size--x-small">${getReadableRole(
        role
      )}</span><br>`;
    });
    return rolesStr;
  } else {
    return "- -";
  }
}

export function logsRenderer(params) {
  if (params.value && params.value.length > 0) {
    let logStr = "";
    params.value.forEach((log) => {
      let color = "green lighten-1";
      if (log.event !== "COMPLETE") {
        color = "teal darken-1";
      }
      logStr += `<span class="mr-2 text-uppercase v-chip v-chip--label ${color} theme--light v-size--x-small">${log.event}</span>`;
    });
    return logStr;
  }
}

export function gatePassRenderer(params) {
  return `<span class="mdi ${params.value === 'Yes' ? "mdi-check green--text" : "mdi-close red--text"}"></span> ${params.value}`;
};

export function gatePassGetter(params) {
  let vtos = _get(params, 'data.vehicleTransferOrders', []);
  let gatePasseCount = 0
  vtos.forEach((vto) => {
    if(_get(vto, 'gatePasses', []).length > 0)
      gatePasseCount++;
  });
  params.value = gatePasseCount > 0 ? "Yes" : "No";
  return params.value;
};

export function isEnclosedGetter(params) {
  let vtos = _get(params, 'data.vehicleTransferOrders', []);
  let count = 0
  if(vtos?.length){
    vtos.forEach((vto) => {
      let fareOptions = _get(vto, 'fare.options', []);
      if(_includes(fareOptions, "Enclosed"))
        count++;
    });
  }
  params.value = count > 0 ? "Yes" : "No";
  return params.value;
};
export function isEnclosedFilterParamsFormater(params) {
  if (_get(params, 'value', "").toString().toLowerCase() === 'yes')
    return "Yes"
  else
    return "No";
}

export function vtoGatePassRenderer(params) {
  return `<span class="mdi ${params.value === 'Yes' ? "mdi-check green--text" : "mdi-close red--text"}"></span> ${params.value}`;
};
export function  vtoGatePassGetter(params) {
  let gatePasseCount = _get(params, 'data.gatePasses', []).length;
  params.value = gatePasseCount > 0 ? "Yes" : "No";
  return params.value;
};

function getReadableRole(role) {
  switch (role) {
    case ROLES.TRANSPORTER:
      return "Transporter";
      break;
    case ROLES.DRIVER:
      return "Driver";
      break;
    case ROLES.RUNBUGGY_SUPER_ADMIN:
      return "Super Admin";
      break;
    case ROLES.RUNBUGGY_ADMIN:
      return "Admin";
      break;
    case ROLES.RUNBUGGY_OPERATIONS:
      return "Operations Admin";
      break;
    case ROLES.ADMIN:
      return "Admin";
      break;
    case ROLES.AUCTION_HOUSE:
      return "Auction House";
      break;
    case ROLES.DEALER:
      return "Dealer";
      break;
    case ROLES.SALES_PERSON:
      return "Sales Person";
      break;
    case ROLES.NOTIFICATIONS_ADMIN:
      return "Notifications Admin";
      break;
    case ROLES.INTERCOMPANY:
      return "Intercompany";
      break;
  }
}

// END TASK MANAGEMENT


// START UAM HELPER FUNCTIONS 
export function valueGetterPrimaryContact(params) {
  let primaryContact = getPrimaryContact(_get(params, "data.contacts", []));
  
  let primaryContactInfo = "";
  if (primaryContact) {
    let name = _get(primaryContact, "name");
    let phone = _get(primaryContact, "phone");
    let email = _get(primaryContact, "email");
    let phoneEmail = undefined;
    if (phone) {
      phoneEmail = `${formatPhoneUAM(phone)}`;
    }
    if (email) {
      if (phone) phoneEmail = `${phone} | ${email}`;
      else phoneEmail = `${email}`;
    }

    if (name) primaryContactInfo = `${name}`;
    if (phoneEmail)
      primaryContactInfo = `${primaryContactInfo} (${phoneEmail})`;
  }
  return primaryContactInfo;
}

export function valueGetterPrimaryContactName(params) {
  let primaryContact = getPrimaryContact(_get(params, "data.contacts", []));
  return `${_get(primaryContact, "name", "")}`;
}

export function valueGetterPrimaryContactPhone(params) {
  let primaryContact = getPrimaryContact(_get(params, "data.contacts", []));
  let phone = _get(primaryContact, "phone");
  return `${formatPhoneUAM(phone)}`;
}

export function valueGetterPrimaryContactEmail(params) {
  let primaryContact = getPrimaryContact(_get(params, "data.contacts", []));
  return `${_get(primaryContact, "email", "")}`;
}

export function cellRenderUserPrimaryContact(params) {
  return getFormatedPrimaryContact(params, _get(params, "data.accounts.0.contacts"));
}

export function cellRenderPrimaryContact(params) {
  return getFormatedPrimaryContact(params, _get(params, "data.contacts"));
}

function getFormatedPrimaryContact(params, contacts) {
  let primaryContact;
  if(_get(params, "data.count") && contacts)
    primaryContact = contacts;
  else
   primaryContact = getPrimaryContact(contacts);

  let primaryContactInfo = "";
  if (primaryContact) {
    let name = _get(primaryContact, "name");
    let phone = _get(primaryContact, "phone");
    let email = _get(primaryContact, "email");
    let phoneEmail = undefined;
    if (phone) {
      phoneEmail = `${formatPhoneUAM(phone)}`;
    }
    if (email) {
      if (phone) phoneEmail = `${phone} | ${email}`;
      else phoneEmail = `${email}`;
    }

    if (name) primaryContactInfo = `${name}<br/>`;
    if (phoneEmail)
      primaryContactInfo = `${primaryContactInfo}<span style="color: #888888">${phoneEmail}</span>`;
  }
  return primaryContactInfo;
}

function formatPhoneUAM(phone) {
  return phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") : "- -";
}

function getPrimaryContact(contacts) {
  let primaryContact = undefined;
  if (contacts && contacts.length > 0) {
    primaryContact = _find(contacts, { type: "primary" });
    if (!primaryContact) primaryContact = contacts[0];
  }
  return primaryContact;
}

export function cellRendererCompanyName(params) {
  let companyName = "";
  let primaryContact = getPrimaryContact(_get(params, "data.contacts", []));
  if (_get(params, "data.name")) {
    companyName = `${_get(params, "data.name")}<br/>`;
  }
  if (primaryContact && _get(primaryContact, "email")) {
    companyName = `${companyName}<span style="color: #888888">${_get(
      primaryContact,
      "email"
    )}</span>`;
  }
  return companyName;
}


export function accountStatusFilterValueFormatter(params) {
  let value = _get(params, 'value');
  let formatedValue = value;
  if (value === "isDeactivated")
    formatedValue = "DEACTIVATED"

  return formatedValue;
}

export function booleanYesNoValueFormatter(params) {
  if (_get(params, 'value', false).toString().toLowerCase() === 'true')
    return "Yes"
  else if (_get(params, 'value', false).toString().toLowerCase() === 'false')
    return "No";
  else
    return "(Blank)";
}

export function booleanYesNoInverseValueFormatter(params) {
  if (_get(params, 'value', false).toString().toLowerCase() === 'true')
    return "No"
  else
    return "Yes"
}


export function accountTypesValueGetter(params) {
  let accounts = _get(params, 'data.accounts')
  return accounts.map(function (account) {
    return account.type;
  });
}

export async function userRolesFilterListServer(params) {
  let excludeRoles = ["INACTIVE", "DISABLE", "INVITED"];
  let roles = await getFilterValuesFromServerForClientside(params);
  let filterdRoles = _filter(roles, (role) => !_includes(excludeRoles, role));
  params.success(filterdRoles);
}

export function isOperationalRenderer(params) {
  let vtos = _get(params, 'data.vehicleTransferOrders', []);
  let nonOperationalCount = 0
  if(vtos && vtos.length){
    vtos.forEach((vto) => {
      if(!_get(vto, 'vehicle.isOperational', true))
        nonOperationalCount++;
    });
  }
  let value = nonOperationalCount > 0 ? "No" : "Yes";
  return `<span class="mdi ${value === 'Yes' ? "mdi-check green--text" : "mdi-close red--text"}"></span> ${value === 'Yes' ? "Yes" : "No"}`;
};
  
export function isOperationalGetter(params) {
  let vtos = _get(params, 'data.vehicleTransferOrders', []);
  let nonOperationalCount = 0
  if(vtos && vtos.length){
    vtos.forEach((vto) => {
      if(!_get(vto, 'vehicle.isOperational', true))
        nonOperationalCount++;
    });
  }
  params.value = nonOperationalCount > 0 ? "No" : "Yes";
  return params.value;
};

export function missingKeysRenderer(params) {
  let vtos =
    _get(params, "data.vehicleTransferOrders") ||
    _get(params, "data.data.vehicleTransferOrders", []);
  let missingKeysCount = 0;
  if (vtos && vtos.length) {
    vtos.forEach((vto) => {
      if (_get(vto, "vehicle.missingKeys")) missingKeysCount++;
    });
  } else if (_get(vtos, "vehicle.missingKeys")) {
    missingKeysCount++;
  }
  let value = missingKeysCount > 0 ? "Yes" : "No";
  return `<span class="mdi ${
    value === "Yes" ? "mdi-check green--text" : "mdi-close red--text"
  }"></span> ${value === "Yes" ? "Yes" : "No"}`;
}

export function missingKeysGetter(params) {
  let vtos =
    _get(params, "data.vehicleTransferOrders") ||
    _get(params, "data.data.vehicleTransferOrders", []);
  let missingKeysCount = 0;
  if (vtos && vtos.length) {
    vtos.forEach((vto) => {
      if (_get(vto, "vehicle.missingKeys")) missingKeysCount++;
    });
  } else if (_get(vtos, "vehicle.missingKeys")) {
    missingKeysCount++;
  }
  params.value = missingKeysCount > 0 ? "Yes" : "No";
  return params.value;
}

export function tpoFareTotalGetter(params) {
  let vtos = _get(params, 'data.vehicleTransferOrders', []);
  let fareTotal = 0;
  if(vtos && vtos.length){
    vtos.forEach((vto) => {
      fareTotal = fareTotal + parseFloat(_get(vto, 'fare.amount', 0));
    });
  }
  params.value = fareTotal;
  return params.value;
}

export function totalOrderFareGetter(params) {
  let orderFareItems = _get(params, 'data.orders.0.fare', []);
  if (orderFareItems && orderFareItems.length) {
    return _get(orderFareItems[orderFareItems.length - 1], 'value', 0);
  }
  return 0;
}


function getFilterValuesFromServerForClientside(params) {
  let fieldName = _get(params, 'colDef.colId');
  let gridViewId = _get(params, 'colDef.filterParams.viewId');
  let paramFilterModel = {
    [fieldName]: {
      filterType: 'distinct'
    }
  };

  const grid = {
    startRow: 0,
    endRow: 100,
    filterModel: paramFilterModel,
    sortModel: []
  };
  return RunBuggyTasksApiService.getFilterValuesFromServer(gridViewId, grid)
    .then((response) => {
      return response;
    })
    .catch(() => {
      return [];
    })
}

// END UAM HELPER FUNCTIONS 